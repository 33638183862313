<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Name -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="kpi.name"
                          maxlength="50"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Short Description -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('SHORT_DESCRIPTION')"
                      label-for="id-short-description"
                    >
                      <b-form-input
                        id="id-short-description"
                        v-model="kpi.str2"
                        maxlength="250"
                        :placeholder="$t('SHORT_DESCRIPTION')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <b-form-input
                        id="id-description"
                        v-model="kpi.str1"
                        maxlength="250"
                        :placeholder="$t('DESCRIPTION')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Type -->
                  <b-col cols="4">
                    <b-form-group
                      :label="$t('TYPE')"
                      label-for="id-type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('TYPE')"
                        rules="required"
                      >
                        <v-select
                          id="vue-select"
                          v-model="valueType"
                          label="name"
                          :options="valueTypeOptions"
                          :clearable="false"
                          required
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Minimum Value -->
                  <b-col cols="4">
                    <b-form-group
                      :label="$t('MINIMUM_VALUE')"
                      label-for="id-minimum"
                    >
                      <b-form-spinbutton
                        id="id-minimum"
                        v-model="kpi.minval"
                        min="0"
                        max="100"
                        :disabled="valueType.id!=valueTypeOptions[2].id"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Maximum Value -->
                  <b-col cols="4">
                    <b-form-group
                      :label="$t('MAXIMUM_VALUE')"
                      label-for="id-maximum"
                    >
                      <b-form-spinbutton
                        id="id-maximum"
                        v-model="kpi.maxval"
                        min="0"
                        max="100"
                        :disabled="valueType.id!=valueTypeOptions[2].id"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Unit -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('UNIT')"
                      label-for="id-unit"
                    >
                      <b-form-input
                        id="id-unit"
                        v-model="kpi.unit"
                        maxlength="20"
                        :placeholder="$t('UNIT')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- description 1 -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('DESCRIPTION_1')"
                      label-for="id-description-1"
                    >
                      <b-form-input
                        id="id-description-1"
                        v-model="kpi.userprompt1"
                        maxlength="60"
                        :placeholder="$t('DESCRIPTION_1')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-description-1"
                        v-model="kpi.rc1"
                        value="1"
                        unchecked-value="0"
                        class="mt-3"
                        name="checkbox-description-1"
                      >
                        {{ $t('REQUIRED') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- description 2 -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('DESCRIPTION_2')"
                      label-for="id-description-2"
                    >
                      <b-form-input
                        id="id-description-2"
                        v-model="kpi.userprompt2"
                        maxlength="60"
                        :placeholder="$t('DESCRIPTION_2')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-description-2"
                        v-model="kpi.rc2"
                        value="1"
                        unchecked-value="0"
                        class="mt-3"
                        name="checkbox-description-2"
                      >
                        {{ $t('REQUIRED') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ kpi.ID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BFormSpinbutton,
  BButton, BForm, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions } from 'vuex'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSpinbutton,
  },
  data() {
    return {
      kpi: {
        ID: 0,
        template_id: 0,
        category_id: 0,
        name: '',
        str1: '',
        str2: '',
        rc1: 0,
        rc2: 0,
        userprompt1: '',
        userprompt2: '',
        value_type: '',
        unit: '',
        minval: 0,
        maxval: 0,
        emoji: '',
      },
      required,
      valueType: '',
      valueTypeOptions: constants.KPI_VALUE_TYPE,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    valueType(newVal) {
      if (newVal.id === this.valueTypeOptions[2].id) {
        this.kpi.minval = this.kpi.minval === 0 ? 1 : this.kpi.minval
        this.kpi.maxval = this.kpi.maxval === 0 ? 5 : this.kpi.maxval
      } else {
        this.kpi.minval = 0
        this.kpi.maxval = 0
      }
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      errorMessage,
    }
  },
  created() {
    // eslint-disable-next-line radix
    this.kpi.ID = parseInt(router.currentRoute.params.id)
    this.kpi.template_id = router.currentRoute.query.templateId
    this.kpi.category_id = router.currentRoute.query.categoryId

    if (router.currentRoute.params.id) {
      if (this.kpi.ID !== 0) {
        this.fetchTemplateKpiByID(this.kpi.ID).then(response => {
          if (response) {
            this.kpi = response.data
            if (this.kpi) {
              this.valueType = this.valueTypeOptions.find(i => i.id === this.kpi.value_type)
            }
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('template', ['createTemplateKpi', 'updateTemplateKpi', 'fetchTemplateKpiByID']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          if (this.valueType) {
            this.kpi.value_type = this.valueType.id
          }
          if ((this.kpi.minval >= this.kpi.maxval) && this.kpi.value_type === this.valueTypeOptions[2].id) {
            this.errorMessage(this.$i18n.t('MESSAGE.MAXIMUM_SHOULD_BE_HIGH'))
          } else if (this.kpi.ID) {
            this.setEmoji()
            this.updateTemplateKpi(this.kpi).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.KPI_UPDATED'))
                this.$router.push({ name: 'template-kpi-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            delete this.kpi.ID
            this.setEmoji()
            this.createTemplateKpi(this.kpi).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.KPI_CREATED'))
                this.$router.push({ name: 'template-kpi-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
    setEmoji() {
      if (this.kpi.value_type === this.valueTypeOptions[2].id) {
        this.kpi.emoji = constants.EMOJI[0].title
      } else {
        this.kpi.minval = 0
        this.kpi.maxval = 0
        this.kpi.emoji = null
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }

}
</style>
